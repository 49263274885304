import React, { useEffect, useMemo, useState } from "react";
import {
  FormControl,
  TextField,
  Switch,
  FormControlLabel,
  Autocomplete,
  Stack,
  Box,
  Grid,
  Snackbar,
  Alert,
  Typography,
  Checkbox
} from "@mui/material";
import axios from "axios";

import { Root } from "./phonebookContactStyles";
import { useOrder } from "../../../Providers/OrderProvider";
import { logEvent } from "../../../helpers/analyticsLogger";
import OtherContact from "./OtherContact";
import { ErrorMessageWrap } from "./editAddContactsStyles";
import ContactCard from "./ContactCard";
import ContactTypeDropdown from "./ContactTypeDropdown";
import PhonebookContact from "./PhonebookContact";
import BuyerSellerForm, {
  TransactingPartyIndividual,
  TransactingPartyOrganization,
  TransactingPartyJoint,
  TransactingPartyAddress
} from "./BuyerSellerForm";
import useOnClickOutside from "../../../hooks/use-on-outside-click";
import AddEditOrderContactFooter from "./AddEditOrderContactFooter";
import UnderwriterOrTitleAgent from "./UnderwriterOrTitleCompany";
import GovernmentContact from "./GovernmentContact";
import SalesRepForm from "./SalesRepForm";
import {
  CompanyBranch,
  Contact,
  ContactType,
  Rep,
  SelectTitleCompany,
  SelectUnderwriter
} from "../../../Providers/types";
import validateEmail from "../../../utils/validateEmail";
import PatriotSearchAlert from "./PatriotSearchAlert";
import BlackListSearchAlert from "./BlackListSearchAlert";
import { BlacklistAndPatriotSearchResults, BlackListResult, NameSearchResult } from "./types";
import { removeMiddleInitial } from "../../../helpers/formatters";

const buyerSellerContactTypes = ["Buyer", "Buyer/Borrower", "Seller"];

const getUniqueValues = (arr: string[]) => [...new Set(arr)].filter(Boolean);

const getDefaultAddress = (contact: Contact) => {
  return {
    address1: contact.address1,
    address2: contact.address2,
    city: contact.city,
    state: contact.state,
    zip: contact.zip
  } as CompanyBranch;
};

export default function AddEditOrderContact({ isEdit, editContact }: { isEdit: boolean; editContact?: Contact }) {
  const {
    order,
    getContactTypeList,
    getOrderContacts,
    getOrderNotes,
    handleUpdatedContacts,
    getOrderInfo,
    contactTypeList,
    setOrder
  } = useOrder();
  const [showInvalidAlert, setShowInvalidAlert] = useState<boolean>(false);
  const [contact, setContact] = useState<Contact>(null);
  const [nameOverride, setNameOverride] = useState<string | null>(null);
  const [selectedCompanyBranch, setSelectedCompanyBranch] = useState<CompanyBranch | null>(
    isEdit ? getDefaultAddress(editContact) : null
  );
  const [contactType, setContactType] = useState<ContactType>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [accountRepList, setAccountRepList] = useState<Rep[]>();
  const [selectedAccountRep, setSelectedAccountsRep] = useState<Rep>();
  const [isClicked, setIsClicked] = useState(false);
  const [salesRep1, setSalesRep1] = useState<Rep | null>(null);
  const [salesRep2, setSalesRep2] = useState<Rep | null>(null);
  const [salesRep3, setSalesRep3] = useState<Rep | null>(null);
  const [showModal, setShowModal] = useState(false);
  const isAttorney = contactType?.name?.toString().toLowerCase().includes("attorney");
  const isSalesPerson = contactType?.name?.toString().toLowerCase().includes("sales person");
  const isPrincipalBuyer = contactType?.name?.toString().toLowerCase().includes("principal - buyer");
  const isPrincipalSeller = contactType?.name?.toString().toLowerCase().includes("principal - seller");
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [loadingRemove, setLoadingRemove] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDisplayPhonebookIframe, setIsDisplayPhonebookIframe] = useState(false);
  const [isNewContact, setIsNewContact] = useState(false);

  const [selectedTitleCompany, setSelectedTitleCompany] = useState<SelectTitleCompany | null>(null);
  const [selectedUnderwriter, setSelectedUnderwriter] = useState<SelectUnderwriter | null>(null);
  const [showPatriotSearchResultsAlert, setShowPatriotSearchResultsAlert] = useState(false);
  const [patriotSearchResult, setPatriotSearchResult] = useState<string[]>([]);
  const [showBlackListSearchResultAlert, setShowBlackListSearchResultAlert] = useState(false);
  const [blackListSearchResult, setBlackListSearchResult] = useState<BlackListResult[]>([]);
  const deleteRef = React.useRef(null);
  const [originalDealOwner, setOriginalDealOwner] = useState<string>(order.dealOwner);
  const [updatedDealOwner, setUpdatedDealOwner] = useState<string>(originalDealOwner);

  const [individual, setIndividual] = useState<TransactingPartyIndividual>({
    firstName: "",
    lastName: "",
    gender: "Male",
    middleName: "",
    vesting: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: ""
    }
  });
  const [organization, setOrganization] = useState<TransactingPartyOrganization>({
    name: "",
    vesting: "",
    payeeName: "",
    organizationType: "",
    address: {
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      phone: "",
      email: ""
    },
    isPayeeNameSameAsName: true
  });
  const [joint, setJoint] = useState<TransactingPartyJoint>({
    jointOne: {
      firstName: "",
      lastName: "",
      gender: "Male",
      middleName: "",
      vesting: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: ""
      }
    },
    jointTwo: {
      firstName: "",
      lastName: "",
      gender: "Female",
      middleName: "",
      vesting: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        phone: "",
        email: ""
      }
    },
    relationshipType: ""
  });
  const [sameAsPropertyAddress, setSameAsPropertyAddress] = useState(true);
  const [address, setAddress] = useState<TransactingPartyAddress>({
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    email: "",
    phone: ""
  });
  const [isJoint, setIsJoint] = useState(false);
  const isBuyerSellerOrganization = Boolean(editContact?.buyerSellerInfo?.organization);
  const [isOrganization, setIsOrganization] = useState(!!isBuyerSellerOrganization);
  const isAccountRep = contactType?.name?.toString().toLowerCase().includes("account rep");
  const isContactUnderwriterOrTitleAgent = ["Underwriter", "Title Company", "TitleCompany"].includes(contactType?.name);
  const isGovernmentContact = contactType?.name === "Government";
  const getAccountReps = async () => {
    const { data } = await axios.get(`/api/Orders/GetAccountReps`);
    const uniquesMap = {};
    //you need to make the list unique since the data returns a few doubles and then the sort will not work
    data.forEach((d: Rep) => {
      if (!uniquesMap[d.fullName]) {
        uniquesMap[d.fullName] = d;
      }
    });

    setAccountRepList(Object.values(uniquesMap));
  };
  const [isLoading, setIsLoading] = useState(false);
  const [invalidEmailIndexes, setInvalidEmailIndexes] = useState<number[]>([]);
  const isBuyerSellerType = buyerSellerContactTypes.includes(contactType?.name);

  let validateForm = () => {
    const emailToValidate = isBuyerSellerType ? address.email : contact?.email;
    if (contact?.emailsSplit && contact?.emailsSplit?.filter((i) => i).length > 0) {
      const invalidIndexes = contact?.emailsSplit
        .map((email, index) => {
          return validateEmail(email) ? null : index;
        })
        .filter((n) => n !== null);
      setInvalidEmailIndexes(invalidIndexes);
      return invalidIndexes.length === 0;
    } else if (emailToValidate) {
      return validateEmail(emailToValidate);
    } else {
      return true;
    }
  };

  const getTransactingParty = () => {
    return {
      organization: isOrganization
        ? {
            ...organization,
            payeeName: organization.isPayeeNameSameAsName ? organization.name : organization.payeeName,
            address
          }
        : undefined,
      joint: isJoint && !isOrganization ? { ...joint, address } : undefined,
      individual: !isOrganization && !isJoint ? { ...individual, address } : undefined
    };
  };

  const formatZipCode = (zip) => {
    if (zip && zip.length === 10) {
      return zip.replace("-", "");
    }
    return zip;
  };

  const getUrl = (isEdit: boolean) => {
    if (isSalesPerson) {
      return `/api/orders/SetSalesReps`;
    }

    if (isContactUnderwriterOrTitleAgent) {
      return `/api/orders/updateTitleCompanyAndUnderwriter`;
    }

    if (isEdit && isBuyerSellerType) {
      const apiUrl = `/api/orders/update${contactType?.name.includes("Buyer") ? "buyer" : "seller"}onorder`;
      return apiUrl;
    }

    if (!isEdit && isBuyerSellerType) {
      return `/api/orders/add${contactType?.name.includes("Buyer") ? "buyer" : "seller"}toorder`;
    }

    return `/api/orders/${isEdit ? "updateordercontact" : "addordercontact"}`;
  };

  const getContactType = (contact: Contact, isEdit: boolean) => {
    if (isEdit) {
      return contact.type || contact.contactType || contactType?.name;
    }
    return contact?.otherContactType ? contact.otherContactType : contactType?.name;
  };

  const buildRequestBody = (isEdit: boolean) => {
    const baseBody = { orderNumber: order.orderNumber };

    if (isSalesPerson) {
      return {
        ...baseBody,
        salesRepIds: [salesRep1, salesRep2, salesRep3].map((rep) => rep?.id).filter((rep) => Boolean(rep))
      };
    }

    if (isContactUnderwriterOrTitleAgent) {
      return {
        ...baseBody,
        titleCompanyLookupCode: selectedTitleCompany.lookupCode || "",
        underwriter: {
          underWriterLookupCode: selectedUnderwriter.underwriterLookupCode || "",
          lookupCode: selectedUnderwriter.lookupCode
        }
      };
    }

    if (!isEdit && isBuyerSellerType) {
      return { ...baseBody, transactingParty: getTransactingParty() };
    }
    if (isEdit && isBuyerSellerType) {
      return {
        ...baseBody,
        transactingParty: getTransactingParty(),
        code: editContact.code
      };
    }
    //const isOtherContact = contactType?.isOther || contact?.type == "Other";
    const isPhonebookContact = contact.id;
    const results = {
      ...baseBody,
      contact: {
        ...contact,
        name: nameOverride ? nameOverride : contact.name,
        address1: isPhonebookContact ? selectedCompanyBranch?.address1 : contact?.address1,
        address2: isPhonebookContact ? selectedCompanyBranch?.address2 : contact?.address2,
        city: isPhonebookContact ? selectedCompanyBranch?.city : contact.city,
        state: isPhonebookContact ? selectedCompanyBranch?.state : contact.state,
        zip: isPhonebookContact ? formatZipCode(selectedCompanyBranch?.zip) : formatZipCode(contact.zip),
        email: contact.emailsSplit?.length > 0 ? contact.emailsSplit.join(";") : contact.email,
        contactType: getContactType(contact, isEdit),
        isOther: !isEdit ? contactType?.isOther : undefined,
        emailPolicyTo: contact?.emailPolicyTo
      }
    };
    delete results.contact.id;
    return results;
  };

  const getFullNameForBlacklistAndPatriotSearching = ({
    firstName,
    middleName,
    lastName
  }: {
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
  }) => {
    if (!firstName || firstName == "TBD" || !lastName) {
      return "";
    }
    const name = [firstName, middleName, lastName].filter(Boolean).join(" ").trim();
    return name;
  };

  const getNamesForPatriotAndBlackListSearch = () => {
    if (isBuyerSellerType && isOrganization) {
      return [organization.name, organization.payeeName].filter(Boolean);
    }
    if (isBuyerSellerType && !isOrganization && isJoint) {
      return [
        getFullNameForBlacklistAndPatriotSearching(joint.jointOne),
        getFullNameForBlacklistAndPatriotSearching(joint.jointTwo)
      ].filter(Boolean);
    }

    if (isBuyerSellerType && !isOrganization) {
      return [getFullNameForBlacklistAndPatriotSearching(individual)].filter(Boolean);
    }
    return [contact.name, contact.company, contact.payeeName].filter(Boolean);
  };

  const searchForPatriotsAndBlackList = async (names: (string | null | undefined)[], orderNumber: string) => {
    const namesToSearch = names
      .map((n) =>
        removeMiddleInitial(n || "")
          .replace(/,?\s*Esq\.?/gi, "")
          .trim()
      )
      .filter(Boolean) as string[];
    const uniqueNamesToSearch = getUniqueValues(namesToSearch);

    if (uniqueNamesToSearch.length == 0) return;

    const { data } = await axios.post<BlacklistAndPatriotSearchResults>("/api/orders/searchForPatriotsAndBlackList", {
      names: uniqueNamesToSearch,
      orderNumber
    });

    const { patriotSearchResults, blackListResults } = data;
    setPatriotSearchResult(patriotSearchResults || []);
    setShowPatriotSearchResultsAlert(patriotSearchResults?.length > 0);
    setBlackListSearchResult(blackListResults || []);
    setShowBlackListSearchResultAlert(data.blackListResults?.length > 0);
  };

  const setDealOwner = async () => {
    const dealOwnerValue = updatedDealOwner === "Buyer" ? 0 : updatedDealOwner === "Seller" ? 1 : null;

    const response = await axios.post("/api/orders/setdealowner", {
      orderNumber: order.orderNumber,
      dealOwner: dealOwnerValue
    });
    setOrder((order) => ({ ...order, dealOwner: updatedDealOwner }));
  };
  const handleSave = async (isEdit: boolean) => {
    setSubmitSuccess(false);
    const formIsValid = validateForm();
    if (!formIsValid && !isContactUnderwriterOrTitleAgent) {
      setShowInvalidAlert(true);
      return;
    } else {
      setShowInvalidAlert(false);
      setIsSubmitting(true);
      setErrorMessage(null);

      const { data } = await axios.post(getUrl(isEdit), buildRequestBody(isEdit));
      if (data.errorMessage) {
        setErrorMessage(data.errorMessage);
        setIsSubmitting(false);
        return;
      }
      const names = getNamesForPatriotAndBlackListSearch();
      await searchForPatriotsAndBlackList(names, order.orderNumber);
      handleUpdatedContacts(true, contact?.code);
      getOrderContacts();
      getOrderNotes();
      await setDealOwner();
      handleEventLogging(isEdit);
      setIsSubmitting(false);
      setSubmitSuccess(true);
    }
  };

  const handleEventLogging = (isEdit) => {
    logEvent(
      "Documents Page",
      isEdit ? "EditContact" : "AddContact",
      JSON.stringify(isEdit ? { before: editContact, updated: contact } : contact, null, 2)
    );
  };

  const addAccountReps = async () => {
    if (!isAccountRep) return;
    const requestParams = {
      orderNumber: order.orderNumber,
      newAccountRepId: selectedAccountRep.id
    };

    const { data } = await axios.post(`/api/Orders/AddOrUpdateAccountRepsToSelect/`, requestParams);

    if (data.errorMessage) {
      setErrorMessage(data.errorMessage);
    } else if (!data.errorMessage) {
      getOrderInfo();
      getOrderContacts();
      setShowModal(false);
    }
  };

  const handleCancel = () => {
    setContact(null);
    setShowModal(false);
    setErrorMessage(null);
  };

  useEffect(() => {
    setShowModal(true);
    getContactTypeList();
    setContact({} as Contact); ///THIS LOOKS DANGEROUS NEEDS TO BE ADJUSTED
    getAccountReps();
  }, []);

  const setLookupCodeContactTypeAndAdditionalData = async (lookupCode) => {
    const { data } = await axios.get(`/api/Orders/getPhonebookContactTypeFromLookupCode?lookupcode=${lookupCode}`);
    const isIndividual = data !== "Company";

    const { data: phonebookData } = await axios.get(
      `/api/Orders/getMomentumContactFromPhonebook?lookupcode=${lookupCode}`
    );

    if (!isIndividual) {
      setContact({ ...editContact, isIndividual });
      return;
    }

    setContact({
      ...editContact,
      id: phonebookData.id,
      branchId: phonebookData.branchId,
      isIndividual,
      role: phonebookData.role
    });
  };

  const individualSetup = () => {
    const nameParts = editContact.name.split(" ");
    const firstName = nameParts[0];
    const middleName = nameParts.length > 2 ? nameParts[1] : "";
    const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";

    const prefilledIndividual = {
      firstName,
      middleName,
      lastName,
      ...editContact?.buyerSellerInfo?.individual
    };
    setIndividual(prefilledIndividual);
    setAddress(editContact?.buyerSellerInfo?.individual?.address);
  };

  const organizationSetup = () => {
    const prefilledOrganization = {
      name: editContact.name,
      ...editContact?.buyerSellerInfo?.organization
    };
    setOrganization(prefilledOrganization);
    setAddress(editContact?.buyerSellerInfo?.organization?.address);
  };
  useEffect(() => {
    if (!editContact) return;

    const contactType = contactTypeList.find(
      (c) =>
        c.name.replace("'", "") === editContact.type.replace("'", "") ||
        c.name.replace(/\s/g, "") == editContact.type.replace(/\s/g, "")
    );
    setContactType(contactType);

    if (contactType?.isPhonebookContact && editContact?.lookupCode) {
      setLookupCodeContactTypeAndAdditionalData(editContact.lookupCode);
    } else {
      setContact({ ...editContact });
    }
    if (buyerSellerContactTypes.includes(contactType?.name)) {
      isBuyerSellerOrganization ? organizationSetup() : individualSetup();
    }
  }, [editContact, contactTypeList]);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape") {
        setShowModal(false);
      }
    };
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  useOnClickOutside(deleteRef, () => setShowDeleteConfirmation(false));

  const renderContactTypeDropdown = () => {
    return (
      (contactType?.isPhonebookContact || !contactType) && (
        <ContactTypeDropdown
          setContactType={setContactType}
          contactType={contactType}
          setContact={setContact}
          contact={contact}
          isEdit={isEdit}
        />
      )
    );
  };

  const renderNoContact = () => {
    return (
      !contactType && (
        <div className="no-contact-yet">
          <ContactCard contact={contact} selectedCompanyBranch={selectedCompanyBranch} nameOverride={nameOverride} />
          <TextField
            size="small"
            placeholder="Search the phonebook/Lookup..."
            style={{ width: "100%" }}
            disabled={true}
          />
        </div>
      )
    );
  };

  const renderPhonebookContact = () => {
    if (isSalesPerson) {
      return (
        <SalesRepForm
          orderNumber={order.orderNumber}
          salesRep1={salesRep1}
          salesRep2={salesRep2}
          salesRep3={salesRep3}
          setSalesRep1={setSalesRep1}
          setSalesRep2={setSalesRep2}
          setSalesRep3={setSalesRep3}
        />
      );
    }

    return (
      contactType?.isPhonebookContact && (
        <PhonebookContact
          isEdit={isEdit}
          editContact={editContact}
          setSelectedAccountsRep={setSelectedAccountsRep}
          accountRepList={accountRepList}
          isAttorney={isAttorney}
          contactType={contactType}
          isSalesPerson={isSalesPerson}
          isAccountRep={isAccountRep}
          setContactType={setContactType}
          contact={contact}
          setContact={setContact}
          selectedCompanyBranch={selectedCompanyBranch}
          setSelectedCompanyBranch={setSelectedCompanyBranch}
          setIsDisplayPhonebookIframe={setIsDisplayPhonebookIframe}
          isDisplayPhonebookIframe={isDisplayPhonebookIframe}
          isNewContact={isNewContact}
          nameOverride={nameOverride}
          setNameOverride={setNameOverride}
          invalidEmailIndexes={invalidEmailIndexes}
          setInvalidEmailIndexes={setInvalidEmailIndexes}
          isPrincipalBuyer={isPrincipalBuyer}
          isPrincipalSeller={isPrincipalSeller}
          updatedDealOwner={updatedDealOwner}
          setUpdatedDealOwner={setUpdatedDealOwner}
          originalDealOwner={originalDealOwner}
        />
      )
    );
  };

  const renderOtherContact = () => {
    if (contactType?.isPhonebookContact) return null;
    if (isGovernmentContact) {
      return <GovernmentContact contact={contact} setContact={setContact} />;
    }

    if (isContactUnderwriterOrTitleAgent) {
      return (
        <UnderwriterOrTitleAgent
          contactType={contactType}
          selectedUnderwriter={selectedUnderwriter}
          setSelectedUnderwriter={setSelectedUnderwriter}
          selectedTitleCompany={selectedTitleCompany}
          setSelectedTitleCompany={setSelectedTitleCompany}
        />
      );
    }

    if (!isBuyerSellerType && contactType?.name) {
      return (
        <OtherContact
          contact={contact}
          setContact={setContact}
          setContactType={setContactType}
          contactType={contactType}
        />
      );
    }

    return null;
  };

  const renderBuyerSellerForm = () => {
    return (
      isBuyerSellerType && (
        <BuyerSellerForm
          individual={individual}
          setIndividual={setIndividual}
          organization={organization}
          setOrganization={setOrganization}
          joint={joint}
          setJoint={setJoint}
          sameAsPropertyAddress={sameAsPropertyAddress}
          setSameAsPropertyAddress={setSameAsPropertyAddress}
          address={address}
          setAddress={setAddress}
          isJoint={isJoint}
          setIsJoint={setIsJoint}
          contactType={contactType?.name}
          isOrganization={isOrganization}
          setIsOrganization={setIsOrganization}
          isEdit={true}
        />
      )
    );
  };

  const renderHeaderText = () => {
    if (isContactUnderwriterOrTitleAgent) {
      return (
        <div className="tile_header" style={{ margin: "0 auto" }}>
          Edit Title Agent & Underwriter
        </div>
      );
    }
    if (isGovernmentContact) {
      return (
        <div className="tile_header" style={{ margin: "0 auto" }}>
          Add Government Contact
        </div>
      );
    }

    if (isEdit) {
      return <div className="tile_header">Edit Contact</div>;
    }

    return <div className="tile_header">Add Contact to Order</div>;
  };

  return (
    showModal && (
      <Root iframeDisplay={isDisplayPhonebookIframe}>
        <div className="tile">
          <div className="tile_header_wrap">{renderHeaderText()}</div>
          {errorMessage && (
            <ErrorMessageWrap>
              <h2>Error: {errorMessage}</h2>
            </ErrorMessageWrap>
          )}
          <Box
            className={isAttorney ? "df jcsb f-wrap" : "df jcc f-wrap"}
            //sx={{ marginBottom: 1 }}
          >
            <FormControl fullWidth sx={{ maxWidth: 320 }}>
              {renderContactTypeDropdown()}
            </FormControl>
            {renderNoContact()}
            {renderPhonebookContact()}
            {renderOtherContact()}
            {renderBuyerSellerForm()}
          </Box>
          {showBlackListSearchResultAlert && submitSuccess && (
            <BlackListSearchAlert
              blackListSearchResult={blackListSearchResult}
              showBlackListSearchResultAlert={showBlackListSearchResultAlert}
              setShowBlackListSearchResultAlert={setShowBlackListSearchResultAlert}
            />
          )}
          {showPatriotSearchResultsAlert && submitSuccess && (
            <PatriotSearchAlert
              showPatriotSearchResultsAlert={showPatriotSearchResultsAlert}
              setShowPatriotSearchResultsAlert={setShowPatriotSearchResultsAlert}
              patriotSearchResult={patriotSearchResult}
            />
          )}
          {!isSalesPerson && !isAccountRep && !isContactUnderwriterOrTitleAgent && !isGovernmentContact && (
            <Box marginTop={1} marginBottom={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={contact?.isMarketingSource}
                    onChange={(e) =>
                      setContact({
                        ...contact,
                        isMarketingSource: !contact.isMarketingSource
                      })
                    }
                  />
                }
                label="Marketing Source"
              />
            </Box>
          )}
          <AddEditOrderContactFooter
            submitSuccess={submitSuccess}
            setShowModal={setShowModal}
            isEdit={isEdit}
            isSubmitting={isSubmitting}
            isSalesPerson={isSalesPerson}
            isAccountRep={isAccountRep}
            handleCancel={handleCancel}
            handleSave={handleSave}
            isClicked={isClicked}
            setIsClicked={setIsClicked}
            addAccountReps={addAccountReps}
            loadingRemove={loadingRemove}
            setLoadingRemove={setLoadingRemove}
            showDeleteConfirmation={showDeleteConfirmation}
            setShowDeleteConfirmation={setShowDeleteConfirmation}
            deleteRef={deleteRef}
            contact={contact}
            editContact={editContact}
            setErrorMessage={setErrorMessage}
            isContactUnderwriterOrTitleAgent={isContactUnderwriterOrTitleAgent}
            showBlackListSearchResultAlert={showBlackListSearchResultAlert}
            showPatriotSearchResultsAlert={showPatriotSearchResultsAlert}
          />
        </div>
        {showInvalidAlert && (
          <Snackbar
            open={showInvalidAlert}
            onClose={() => setShowInvalidAlert(false)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={() => setShowInvalidAlert(false)} severity="error" variant="filled" sx={{ width: "100%" }}>
              Please enter a valid Email.
              <Typography variant="body2" fontWeight={900} display="inline-block"></Typography>{" "}
            </Alert>
          </Snackbar>
        )}
      </Root>
    )
  );
}
